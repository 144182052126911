import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(private router: Router) { }
  goToLink(string: string) {
    this.router.navigate([string]);
  }
  goToDashboard(param?: any[]) {
    this.router.navigate(['/dashboard/home']);
  }
  goToLogin() {
    this.router.navigate(['/']);
  }
  goToRetailRequest() {
    this.router.navigate(['/dashboard/retailers/request']);
  }
  goToAddRetailer() {
    this.router.navigate(['/dashboard/retailers/add']);
  }


  logIn() {
    this.router.navigate(['/login']);
  }
}
