import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {Business, businesses} from "../../../../../../fe-global-components/mockup_data/mockup_data";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-retailers-list',
  templateUrl: './retailers-list.component.html',
  styleUrl: './retailers-list.component.scss',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RetailersListComponent implements OnInit {
  isTableExpanded = false;

  dataProductsList = new MatTableDataSource();
  displayedColumns: string[] = ['checkbox', 'name', 'categories', 'brand', 'actions'];
  ngOnInit() {
    this.dataProductsList.data = businesses;
    for (const business of this.dataProductsList.data) {
      // @ts-ignore
      business.isExpanded = false;
    }
  }

  // Toggel Rows
  toggleTableRows() {
    this.isTableExpanded = !this.isTableExpanded;

    this.dataProductsList.data.forEach((row: any) => {
      row.isExpanded = this.isTableExpanded;
    })
  }

  toggleProduct(product: Business) {
    product.isExpanded = !product.isExpanded
    console.log(product)
    console.log(this.dataProductsList.data)
  }
}
