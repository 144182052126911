// https://support.panierdequartier.fr/article/10-gerer-une-commande
export enum sale_status {
    WaitingMoney = 'Attente paiement',
    Payed = 'Payé',
    Pending = 'En attente d\'expedition',
    Send = 'Expédié',
    Retrieved = 'Réception confirmé',
    Returned = 'Renvoyé',
    Refunded = 'Remboursé',
    Closed = 'Clos',
}

export enum color {
    Grey = 'grey',
    Blue = 'blue',
    Red = 'red',
    Orange = 'orange',
    Green = 'green'
}