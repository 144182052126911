import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BffApiService} from "../../shared/bff-api.service";
import {NavigationService} from "../../shared/service/navigation.service";

@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent {
  loginForm: FormGroup;
  constructor(private fb: FormBuilder,
              private bff: BffApiService,
              public nav: NavigationService) {
    this.loginForm  = this.fb.group({
      email: [null, Validators.required],
      password: [null, Validators.required],
    })
  } // constructor

  submit() {
    if (this.loginForm.invalid) {
      console.error('VESTRA - invalid form')
      // TODO throw invalid error
      return
    }
    // this.bff.getSecret().subscribe(data => {
    //   //TODO I'm redirected to API home
    //   this.bff.login(this.loginForm.value).subscribe(data => {
    //     //todo
    //     // this.nav.goToDashboard()
    //   })
    // })
    this.nav.goToDashboard()
  }

}
