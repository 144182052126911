<p>Récupération des données liées à chaque transaction chronologique</p>
<ul class="list-disc list-inside bg-gray-200">
  <li>Date, Heure de la commandet</li>
  <li>Vente : article, numéro article, montant, commission</li>
  <li>Magasin + IBAN + mail</li>
  <li>Consommateur data : Nom, Prénom, mail, n tel.</li>
  <li>Retrait de l’article : Oui / Non. --> Date heure pour validation facturation commission</li>
  <li>Lier à un bouton de confirmation sur interface Magasin</li>
</ul>


<div>
  <div class="md:flex md:items-center md:justify-between py-8">
    <div class="min-w-0 flex-1">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Transactions</h2>
    </div>
<!--    <div class="mt-4 flex md:ml-4 md:mt-0">-->
<!--      <button type="button" class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Ajouter Manuellement un commerçant</button>-->
<!--      <button type="button" class="ml-3 inline-flex items-center rounded-md bg-stone-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">Afficher les nouvelles requêtes</button>-->
<!--    </div>-->
  </div>
  <!--
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
  <div class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
    <h3 class="text-base font-semibold leading-6 text-gray-900">Liste des transactions</h3>
    <div class="mt-3 sm:ml-4 sm:mt-0">
      <label for="mobile-search-candidate" class="sr-only">Search</label>
      <label for="desktop-search-candidate" class="sr-only">Search</label>
      <div class="flex rounded-md shadow-sm">
        <div class="relative flex-grow focus-within:z-10">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clip-rule="evenodd" />
            </svg>
          </div>
          <input type="text" name="mobile-search-candidate" id="mobile-search-candidate" class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:hidden" placeholder="Search">
          <input type="text" name="desktop-search-candidate" id="desktop-search-candidate" class="hidden w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:block" placeholder="Search candidates">
        </div>
        <button (click)="toggleTableRows()" type="button" class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          <svg class="-ml-0.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M2 3.75A.75.75 0 012.75 3h11.5a.75.75 0 010 1.5H2.75A.75.75 0 012 3.75zM2 7.5a.75.75 0 01.75-.75h6.365a.75.75 0 010 1.5H2.75A.75.75 0 012 7.5zM14 7a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02l-1.95-2.1v6.59a.75.75 0 01-1.5 0V9.66l-1.95 2.1a.75.75 0 11-1.1-1.02l3.25-3.5A.75.75 0 0114 7zM2 11.25a.75.75 0 01.75-.75H7A.75.75 0 017 12H2.75a.75.75 0 01-.75-.75z" clip-rule="evenodd" />
          </svg>
          Afficher tous les détails
          <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
  </div>


  <table mat-table [dataSource]="dataProductsList" multiTemplateDataRows>

    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let product">
        <mat-checkbox class="example-margin"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Magasin  </th>
      <td mat-cell *matCellDef="let product"> {{product.business.business_name}} </td>
    </ng-container>

    <ng-container matColumnDef="categories">
      <th mat-header-cell *matHeaderCellDef> Statut </th>
      <td mat-cell *matCellDef="let product"> {{product.status}}</td>
    </ng-container>

    <ng-container matColumnDef="brand">
      <th mat-header-cell *matHeaderCellDef> Mise à jour </th>
      <td mat-cell *matCellDef="let product"> {{product.updated_at}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let product">
        <a mat-button (click)="product.isExpanded = !product.isExpanded"> Détails </a>
      </td>
    </ng-container>

    <!-- Template for details row -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let product" [attr.colspan]="displayedColumns.length">

        <div class="row student-product-detail" [@detailExpand]="product.isExpanded ? 'expanded' : 'collapsed'">
         <div class="flex lg:px-8">
           <div class="mx-auto w-1/3 max-w-7xl px-6 lg:px-8 min-w-72">
               <div>Commercant détail</div>
              <div>
                <p>{{product.business.business_name}}</p>
                <p>{{product.business.email}}</p>
                <p>{{product.business.phone}}</p>
              </div>
           </div>

           <div class="mx-auto w-1/3 max-w-7xl px-6 lg:px-8 min-w-72">
               <div>Consomateur détail</div>
               <div>
                 <p>{{product.user.first_name}} {{product.user.last_name}}</p>
                 <p>{{product.user.email}}</p>
                 <p>{{product.user.phone}}</p>
               </div>
           </div>

           <div class="mx-auto w-1/3 max-w-7xl px-6 lg:px-8 min-w-72">
             <mat-list>
               <div mat-subheader>Commande détail</div>
               <mat-list-item *ngFor="let article of product.articles">
                 <div mat-line>{{article.name }}</div>
                 <div mat-line>{{article.brand }}</div>
                 <div mat-line>{{article.description }}</div>
                 <div mat-line>{{article.price }}</div>
                 <div mat-line>{{article.color }}</div>
                 <div mat-line> {{article.size }} | Quantitée: {{article.quantity}}</div>
               </mat-list-item>
             </mat-list>
           </div>
         </div>


        </div>

      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let product; columns: displayedColumns;" class="student-product-row"
        [class.student-expanded-row]="product.isExpanded"></tr>

    <!-- Extra row to show detail content column -->
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="student-detail-row"></tr>

  </table>

</div>


