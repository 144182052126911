import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {Page404Component} from "./page/page404/page404.component";
import {DashboardComponent} from "./page/dashboard/dashboard.component";
import {PageLoginComponent} from "./page/page-login/page-login.component";
import {ChildRetailerComponent} from "./page/dashboard-children/child-retailer/child-retailer.component";
import {HomePageComponent} from "./page/dashboard-children/home-page/home-page.component";
import {PageTransactionsComponent} from "./page/dashboard-children/page-transactions/page-transactions.component";
import {AddRetailerComponent} from "./page/retailers-action/add-retailer/add-retailer.component";
import {RetailersRequestsComponent} from "./page/retailers-action/retailers-requests/retailers-requests.component";
import {RetailersListComponent} from "./page/retailers-action/retailers-list/retailers-list.component";

const routes: Routes = [
  {
    path: '',
    component: PageLoginComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      {
        path: 'home',
        component: HomePageComponent,
      },
      {
        path: 'retailers',
        component: ChildRetailerComponent,
        children: [
          {
            path: '',
            component: RetailersListComponent,
          },
          {
            path: 'add',
            component: AddRetailerComponent,
          },
          {
            path: 'request',
            component: RetailersRequestsComponent,
          },
        ]
      },

      {
        path: 'transactions',
        component: PageTransactionsComponent,
      },
    ]
  },
  {
    path: '404',
    component: Page404Component,
  },
  {
    path: '**', redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
