import {Component, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BffApiService} from "../../../shared/bff-api.service";
import {NavigationService} from "../../../shared/service/navigation.service";
import {location} from "../../../../../../fe-global-components/constants/location";

@Component({
  selector: 'app-add-retailer',
  templateUrl: './add-retailer.component.html',
  styleUrl: './add-retailer.component.scss',
  animations: [
    trigger('opacityLeave', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms ease-in', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class AddRetailerComponent {

  protected readonly states = location;
  registerForm: FormGroup;

  constructor(private fb: FormBuilder,
              private bff: BffApiService,
              public nav: NavigationService) {
    this.registerForm = this.fb.group({
      businessName: [null, Validators.required],
      streetAndNumber: [null, Validators.required],
      zip: [null, Validators.required],
      city: [null, Validators.required],
      province: [null, Validators.required],
      infos: [null, Validators.required],
      logo: [null],
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      email: [null, Validators.required],
      phone: [null, Validators.required],
      cashierProgram: [null],
      iban: [null],
    })
  }

  sendRequest() {
    if(this.registerForm.invalid) {
      //TODO send info
      return
    }
    this.bff.addRetailer(this.registerForm.value).subscribe(data => {
      console.log(data)
      // todo: go to resume the request and say thank you !
    })
  }



}
