import {Component} from '@angular/core';
import {NavigationService} from "../../../shared/service/navigation.service";

@Component({
  selector: 'app-child-retailer',
  templateUrl: './child-retailer.component.html',
  styleUrls: ['./child-retailer.component.scss'],
})
export class ChildRetailerComponent {
  constructor(protected nav: NavigationService) {
  }





}
