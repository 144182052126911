import {color, sale_status} from "../enums";
import {Address, Color, Order, OrderStatus, Product, ProductVariant, Size, User} from "../interfaces/interfaces";
import {Express, OnSite, Standard} from "../constants/deliveryMethods";

/**
 * COLORs AND SIZEs
 */
export const BLACK: Color = {
    id: 9,
    name: 'Noir',
    backgroundColor: 'rgb(17 24 39)',
    ringColor: 'rgb(17 24 39)',
    value: 'black'
}
export const GEY: Color = {
    id: 1,
    name: 'Gris',
    backgroundColor: 'rgb(156 163 175)',
    ringColor: 'rgb(156 163 175)',
    value: 'heater_grey'
}
export const WHITE: Color = {
    id: 2,
    name: 'Blanc',
    backgroundColor: 'rgb(255 255 255)',
    ringColor: 'rgb(156 163 175)', // ;ring-gray-400
    value: 'white'
}
export const BLUE: Color = {
    id: 3,
    name: 'Bleu',
    backgroundColor: 'rgb(96 165 250)',
    ringColor: 'rgb(156 163 175)',
    value: 'blue'
}
export const ORANGE: Color = {
    id: 4,
    name: 'Orange',
    backgroundColor: 'rgb(251 146 60)',
    ringColor: 'rgb(156 163 175)',
    value: 'orange'
}
export const RED: Color ={
    id: 5,
    name: 'Rouge',
    backgroundColor: 'rgb(248 113 113)',
    ringColor: 'rgb(156 163 175)',
    value: 'red'
}
export const PINK: Color = {
    id: 6,
    name: 'Rose',
    backgroundColor: 'rgb(244 114 182)',
    ringColor: 'rgb(156 163 175)',
    value: 'pink'
}
export const GREEN: Color = {
    id: 7,
    name: 'Vert',
    backgroundColor: 'rgb(74 222 128)',
    ringColor: 'rgb(156 163 175)',
    value: 'green'
}
export const YELLOW: Color = {
    id: 8,
    name: 'Jaune',
    backgroundColor: 'rgb(250 204 21)',
    ringColor: 'rgb(156 163 175)',
    value: 'yellow'
}
export const colors: Color[] = [ BLACK, GEY, WHITE, GREEN, YELLOW, ORANGE, RED, PINK, BLUE ];


export const XXS: Size = {
    id: 1,
    name: 'XXS',
    available: true
}
export const XS: Size = {
    id: 2,
    name: 'XS',
    available: true
}
export const S: Size = {
    id: 3,
    name: 'S',
    available: true
}
export const M: Size = {
    id: 4,
    name: 'M',
    available: true
}
export const L: Size = {
    id: 5,
    name: 'L',
    available: true
}
export const XL: Size = {
    id: 6,
    name: 'XL',
    available: true
}
export const XXL: Size = {
    id: 7,
    name: 'XXL',
    available: true
}
export const sizes: Size[] = [ XXS, XS, S, M, L, XL, XXL];


/**
 * For Retailer stock management
 */
export const product_variant: ProductVariant[] = [
    {"id":1, color: YELLOW, size: XS, quantity: 2,},
    {"id":2, color: YELLOW, size :S, quantity: 2,},
    {"id":3, color: GREEN, size :M, quantity: 2,},
    {"id":4, color: BLUE, size :L, quantity: 2,},
    {"id":5, color: YELLOW, size :XL, quantity: 2,},
    {"id":6, color: YELLOW, size :XXL, quantity: 2,},
]

/**
 * For Retailer stock management
 */
export const products: Product[] = [
    {id: 1, name: 'Hydrogen', categories: '1.0079', brand: 'H', products: product_variant, description: '', price: 20.00, old_price: 20.00},
    {id: 2, name: 'Helium', categories: '4.0026', brand: 'He', products: product_variant, description: '', price: 20.00, old_price: 20.00},
    {id: 3, name: 'Lithium', categories: '6.941', brand: 'Li', products: product_variant, description: '', price: 20.00, old_price: 20.00},
    {id: 4, name: 'Beryllium', categories: '9.0122', brand: 'Be', products: product_variant, description: '', price: 20.00, old_price: 20.00},
    {id: 5, name: 'Beryllium', categories: '9.0122', brand: 'Be', products: product_variant, description: '', price: 20.00, old_price: 20.00},
    {id: 6, name: 'Beryllium', categories: '9.0122', brand: 'Be', products: product_variant, description: '', price: 20.00, old_price: 20.00},
    {id: 7, name: 'Beryllium', categories: '9.0122', brand: 'Be', products: product_variant, description: '', price: 20.00, old_price: 20.00},
    {id: 8, name: 'Beryllium', categories: '9.0122', brand: 'Be', products: product_variant, description: '', price: 20.00, old_price: 20.00},
    {id: 9, name: 'Beryllium', categories: '9.0122', brand: 'Be', products: product_variant, description: '', price: 20.00, old_price: 20.00},
    {id: 10, name: 'Beryllium', categories: '9.0122', brand: 'Be', products: product_variant, description: '', price: 20.00, old_price: 20.00},
    {id: 11, name: 'Beryllium', categories: '9.0122', brand: 'Be', products: product_variant, description: '', price: 20.00, old_price: 20.00},
    {id: 12, name: 'Beryllium', categories: '9.0122', brand: 'Be', products: product_variant, description: '', price: 20.00, old_price: 20.00},
    {id: 13, name: 'Beryllium', categories: '9.0122', brand: 'Be', products: product_variant, description: '', price: 20.00, old_price: 20.00},
];

export const product: Product =
    {id: 1, name: 'Mockup', categories: '1.0079', brand: 'brand', products: product_variant, description: '', price: 20.00, old_price: 20.00};



export const order_status: OrderStatus[] = [
    {id:'Réservé', name: sale_status.WaitingMoney, description: '', color: color.Grey},
    {id:'Payé', name: sale_status.Payed, description: '', color: color.Green},
    {id:'Pending', name: sale_status.Pending, description: '', color: color.Orange},
    {id:'Livré', name: sale_status.Send, description: '', color: color.Green},
    {id:'Récupéré', name: sale_status.Retrieved, description: '', color: color.Blue},
    {id:'Retourné', name: sale_status.Returned, description: '', color: color.Orange},
    {id:'Rembourssé', name: sale_status.Refunded, description: '', color: color.Grey},
    {id:'Terminé', name: sale_status.Closed, description: '', color: color.Blue},
]


export interface PaymentMethod {
    id: number;
    IBAN: string;
    type: string;
}

export interface Business extends User {
    id: number;
    business_name: string;
    paymentMethod: PaymentMethod;
    isExpanded?: boolean
}

export interface Transaction {
    id: number;
    date: string;
    business: Business
    consumer: User
    order_status: OrderStatus
}


export const paymentMethod: PaymentMethod = {
    id: 1,
    IBAN: 'CH0989144364944361436',
    type: 'MasterCard',
}

export const address1: Address = {
    street:  "Hauptstrasse",
    number:  "93",
    City:  "Mönchaltorf",
    ZIP:  8617,
    State:  "Bern",
    Country:  "Switzerland",
}

export const business : Business = {
    email: "nonux_owoje68@gmail.com",
    first_name: "Prénom",
    invoice_address: address1,
    last_name: "Nom de famille",
    phone: "000 000 00 00",
    id: 1,
    business_name: 'BUSINESSNAME',
    paymentMethod: paymentMethod,
}

export const waitingMoney : OrderStatus = {
    id:'WaitingMoney',
    name: sale_status.WaitingMoney,
    description: '', color: color.Grey}


export const corine: User = {
    "first_name": "Corrinne", "last_name": "Clears",
    "email": "cclears0@goo.ne.jp", "phone": "+81 645 603 1348",
    // "invoice_address": "62 Thierer Pass",
    // "delivery_address": "86 Schmedeman Terrace",
}
export const bertie: User = {
    "first_name": "Bertie",
    "last_name": "Narbett",
    "email": "bnarbett3@sbwire.com",
    "phone": "+62 550 779 1778",
    // "invoice_address": "85793 Meadow Vale Park",
    // "delivery_address": "6 Scofield Terrace",
}


export const orders: Order[] = [
    {
        "id": 1,
        user: corine,
        deliveryMethode: OnSite,
        "status": sale_status.Pending,
        created_at: 'date',
        updated_at: 'date',
        "articles": [
            {
                "id": 1,
                "brand": "GALANTAMINE HYDROBROMIDE",
                "name": "Hybrid Plum",
                "description": "Anom digestive syst NOS",
                "price": 269.22,
                "old_price": 49,
                color: 'blue',
                size: 'xl',
                quantity: 1,
                categories: 'shirt'
            },
            {
                "id": 2,
                "brand": "Ibutilide Fumarate",
                "name": "Chess-apple",
                "description": "Hered fructose intoleran",
                "price": 175.23,
                "old_price": 29,
                color: 'blue',
                size: 'xl',
                quantity: 1,
                categories: 'shirt'
            },
            {
                "id": 3,
                "brand": "Streptococcus Remedy",
                "name": "Polianthes",
                "description": "Bone donor",
                "price": 205.32,
                "old_price": 130,
                color: 'blue',
                size: 'xl',
                quantity: 1,
                categories: 'shirt'
            },
            {
                "id": 4,
                "brand": "FACE IT HD PERFECT BB SPF30 PA 01",
                "name": "Coastal Hedgenettle",
                "description": "Osseo fail dental implnt",
                "price": 267.5,
                "old_price": 38,
                color: 'blue',
                size: 'xl',
                quantity: 1,
                categories: 'shirt'
            },
        ], "total": 211.51,
        business: business
    },
    {
        "id": 2,
        user: corine,
        deliveryMethode: OnSite,
        "status": sale_status.WaitingMoney,
        "articles": [],
        created_at: 'date',
        updated_at: 'date',
        "total": 33.13,
        business: business
    },
    {
        "id": 3,
        user: corine,
        deliveryMethode: OnSite,
        created_at: 'date',
        updated_at: 'date',
        "status": sale_status.Closed,
        "articles": [],
        "total": 198.6,
        business: business
    },
    {
        "id": 4,
        user: bertie,
        deliveryMethode: Express,
        created_at: 'date',
        updated_at: 'date',
        "status": sale_status.Closed,
        "articles": [],
        "total": 258.91,
        business: business
    },
    {
        "id": 5,
        user: bertie,
        deliveryMethode: Standard,
        created_at: 'date',
        updated_at: 'date',
        "status": sale_status.Closed,
        "articles": [],
        "total": 195.04,
        business: business
    },
    {
        "id": 6,
        user: bertie,
        deliveryMethode: Standard,
        created_at: 'date',
        updated_at: 'date',
        "status": sale_status.Returned,
        "articles": [],
        "total": 134.22,
        business: business
    },
    {
        "id": 7,
        user: bertie,
        deliveryMethode: Standard,
        created_at: 'date',
        updated_at: 'date',
        "status": sale_status.Returned,
        "articles": [],
        "total": 48.79,
        business: business
    },
    {
        "id": 8,
        user: bertie,
        deliveryMethode: Standard,
        created_at: 'date',
        updated_at: 'date',
        "status": sale_status.Retrieved,
        "articles": [],
        "total": 232.77,
        business: business
    },
    {
        "id": 9,
        user: bertie,
        deliveryMethode: Standard,
        created_at: 'date',
        updated_at: 'date',
        "status": sale_status.Send,
        "articles": [],
        "total": 52.11,
        business: business
    },
    {
        "id": 10,
        user: bertie,
        deliveryMethode: Standard,
        created_at: 'date',
        updated_at: 'date',
        "status": sale_status.Pending,
        "articles": [],
        "total": 81.1,
        business: business
    },
]


export const businesses : Business[] = [
    business, business, business, business, business, business, business, business, business, business, business, business, business
]
export const transactions : Transaction[] = [
    {id: 1, date: 'December 17, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'December 18, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'December 19, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'December 12, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'December 17, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'December 24, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'January 17, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'January 10, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'January 12, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'January 19, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'January 22, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'January 24, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'January 27, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'February 1, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'February 4, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'February 8, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'February 12, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'February 14, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'February 17, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'February 18, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'February 20, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'February 21, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'February 22, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney },
    {id: 1, date: 'February 24, 2023 03:24:00', business:business, consumer:bertie, order_status: waitingMoney }
]


//     - Propriétés du matériel :
export const fabric_propreties : any[] = [
    {name: 'Coupe-vent'},
    {name: 'Inhibant les odeurs'},
    {name: 'Isolant thermique'},
    {name: 'Respirant'},
    {name: 'Elastique'}
]

export const fabric : any[] = [
    {name: 'Angora'},
    {name: 'Aramide'},
    {name: 'Coton'},
    {name: 'Cuir'},
    {name: 'Cuir végétal'},
    {name: 'Chino'},
    {name: 'Corduroy'},
    {name: 'Denim'},
    {name: 'Duvet'},
    {name: 'Duvet de canard'},
    {name: 'Duvet d’oie'},
    {name: 'Duvet synthétique / polyester'},
    {name: 'Elasthanne'},
    {name: 'Fibre de pierre'},
    {name: 'Lyocell'},
    {name: 'Lin'},
    {name: 'Laine'},
    {name: 'Laine Merinos'},
    {name: 'Mohair'},
    {name: 'Nylon'},
    {name: 'Polaire'},
    {name: 'Polyester'},
    {name: 'Polyamide'},
    {name: 'Polyimide'},
    {name: 'Polylactide'},
    {name: 'Polyéthylène'},
    {name: 'Polypropylène'},
    {name: 'Soie'},
    {name: 'Velours'},
    {name: 'Autres'},
]
