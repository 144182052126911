<div class="bg-white py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl lg:max-w-none">
      <div class="text-center">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Bienvenue</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600">Voici les derniers chiffres</p>
      </div>

      <div class="flex mb-4">
        <dl class="w-full sm:w-1/2 mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2">
          <div class="flex flex-col bg-gray-400/5 p-8">
            <dt class="text-sm font-semibold leading-6 text-gray-600">Chiffre d'affaires total</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">8000</dd>
          </div>
          <div class="flex flex-col bg-gray-400/5 p-8">
            <dt class="text-sm font-semibold leading-6 text-gray-600">Chiffre d'affaires depuis la dernière facturation</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">8000</dd>
          </div>
          <div class="flex flex-col bg-gray-400/5 p-8">
            <dt class="text-sm font-semibold leading-6 text-gray-600">Commissions totales</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">8000</dd>
          </div>
          <div class="flex flex-col bg-gray-400/5 p-8">
            <dt class="text-sm font-semibold leading-6 text-gray-600">Commissions depuis la dernière facturation</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">8000</dd>
          </div>
        </dl>

        <div class="w-full sm:w-1/2 bg-white py-24 sm:py-32">
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
              <div class="mx-auto flex max-w-xs flex-col gap-y-4">
              </div>
              <div class="mx-auto flex max-w-xs flex-col gap-y-4">
                <dt class="text-base leading-7 text-gray-600">Nombre de magasins</dt>
                <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">1234</dd>
              </div>
              <div class="mx-auto flex max-w-xs flex-col gap-y-4">
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



