<div>
  <div class="md:flex md:items-center md:justify-between py-8">
    <div class="min-w-0 flex-1">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Commerçants</h2>
    </div>
    <div class="mt-4 flex md:ml-4 md:mt-0">
      <button type="button"
              (click)="nav.goToAddRetailer()"
              class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        Ajouter Manuellement un commerçant</button>
      <button type="button"
              (click)="nav.goToRetailRequest()"
              class="ml-3 inline-flex items-center rounded-md bg-stone-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">
        Afficher les nouvelles requêtes</button>
    </div>
  </div>


<!--  <div class="border-b border-gray-200">-->
<!--    <div class="sm:flex sm:items-baseline">-->
<!--      <h3 class="text-base font-semibold leading-6 text-gray-900">Issues</h3>-->
<!--      <div class="mt-4 sm:ml-10 sm:mt-0">-->
<!--        <nav class="-mb-px flex space-x-8">-->
<!--          &lt;!&ndash; Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" &ndash;&gt;-->
<!--          <a href="#" class="border-indigo-500 text-indigo-600 whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium" aria-current="page">Open</a>-->
<!--          <a href="#" class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium">Closed</a>-->
<!--        </nav>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <router-outlet></router-outlet>

</div>


