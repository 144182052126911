import { Component } from '@angular/core';

@Component({
  selector: 'app-retailers-requests',
  templateUrl: './retailers-requests.component.html',
  styleUrl: './retailers-requests.component.scss'
})
export class RetailersRequestsComponent {

}
