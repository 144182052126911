import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BffApiService {
  apiUrl: string =  'https://api-vestra-staging.exe-cute.ch/api/v1/company';
  constructor(private http: HttpClient) { }

  getToken(): Observable<any> {
    const url = 'https://api-vestra-staging.exe-cute.ch/sanctum/csrf-cookie';
    return this.http.get(url, { withCredentials: true });
  }

  login(form: any): Observable<any> {
    const url = `/login?email=${form.email}&password=${form.password}`
    return this.http.post(url, '',{ withCredentials: true });
  }

  logout(): Observable<any> {
    const url = `/logout`
    return this.http.post(url, '',{ withCredentials: true });
  }

  getRetailers(): Observable<any> {
    const url = `/retailers`
    return this.http.get(url, { withCredentials: true });
  }

  addRetailer(form: any): Observable<any> {
    const url = `/retailers/add`
    return this.http.post(url, form,{ withCredentials: true });
  }

  editeRetailer(): Observable<any> {
    const url = `/retailers/edit`
    return this.http.put(url, '',{ withCredentials: true });
  }



}
