<!--
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->

  <div class="mx-auto">

    <form [formGroup]="registerForm">
      <div class="space-y-12">
        <div class="border-b border-gray-900/10 pb-12">
          <h2 class="text-base font-semibold leading-7 text-gray-900">Profil</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Ces informations seront affichées publiquement.</p>

          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-4">
              <label for="businessName" class="block text-sm font-medium leading-6 text-gray-900">Nom du magasin</label>
              <div class="mt-2  bg-white">
                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-stone-600 sm:max-w-md">
                  <input type="text"
                         formControlName="businessName"
                         name="businessName" id="businessName" autocomplete="businessName" class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="Nom">
                </div>
              </div>
            </div>

            <div class="col-span-full">
              <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Rue et n°</label>
              <div class="mt-2">
                <input type="text"
                       formControlName="streetAndNumber"
                       name="street-address" id="street-address" autocomplete="street-address" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
              </div>
            </div>


            <div class="sm:col-span-2 sm:col-start-1">
              <label for="postal-code" class="block text-sm font-medium leading-6 text-gray-900">ZIP</label>
              <div class="mt-2">
                <input type="text"
                       formControlName="zip"
                       name="postal-code" id="postal-code" autocomplete="postal-code" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="city" class="block text-sm font-medium leading-6 text-gray-900">Ville</label>
              <div class="mt-2">
                <input type="text"
                       formControlName="city"
                       name="city" id="city" autocomplete="address-level2" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Canton</label>
              <div class="mt-2">
                <select id="country"
                        formControlName="province"
                        name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option *ngFor="let state of states">{{state.name}}</option>
                </select>
              </div>
            </div>



            <div class="col-span-full">
              <label for="about" class="block text-sm font-medium leading-6 text-gray-900">A propos</label>
              <div class="mt-2">
                <textarea id="about"
                          formControlName="infos"
                          name="about" rows="3" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"></textarea>
              </div>
              <p class="mt-3 text-sm leading-6 text-gray-600">Quelques lignes sur votre boutique</p>
            </div>

            <div class="col-span-full">
              <label for="cover-photo" class="block text-sm font-medium leading-6 text-gray-900">Logo</label>
              <div id="cover-photo" class="bg-white mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div class="text-center">
                  <svg class="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
                  </svg>
                  <div class="mt-4 flex text-sm leading-6 text-gray-600">
                    <label for="file-upload" class="relative cursor-pointer rounded-md bg-white font-semibold text-stone-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-stone-600 focus-within:ring-offset-2 hover:text-stone-500">
                      <span>Charger un fichier</span>
                      <input id="file-upload"
                             formControlName="logo"
                             name="file-upload" type="file" class="sr-only">
                    </label>
                    <p class="pl-1">ou glisser</p>
                  </div>
                  <p class="text-xs leading-5 text-gray-600">PNG, JPG < 10MB</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="border-b border-gray-900/10 pb-12">
          <h2 class="text-base font-semibold leading-7 text-gray-900">Informations confidentielles</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Ces informations seront traitées avec toute la précaution et la sécurité nécessaires.</p>


          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="col-span-full">
              <h2 class="text-sm font-semibold leading-7 text-gray-900">Personne de contact</h2>
            </div>

            <div class="sm:col-span-3">
              <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Prénom</label>
              <div class="mt-2">
                <input type="text"
                       formControlName="firstname"
                       name="first-name" id="first-name" autocomplete="given-name"
                       class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                       placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
              </div>
            </div>

            <div class="sm:col-span-3">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Nom</label>
              <div class="mt-2">
                <input type="text"
                       formControlName="lastname"
                       name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
              </div>
            </div>

            <div class="sm:col-span-4">
              <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Adresse email</label>
              <div class="mt-2">
                <input id="email"
                       formControlName="email"
                       name="email" type="email" autocomplete="email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
              </div>
            </div>

            <div class="sm:col-span-4">
              <label for="phone" class="block text-sm font-medium leading-6 text-gray-900">Téléphone</label>
              <div class="mt-2  bg-white">
                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-stone-600 sm:max-w-md">
                  <span class="flex select-none items-center pl-3 text-gray-500 sm:text-sm">+41 </span>
                  <input type="text"
                         formControlName="phone"
                         name="phone" id="phone" autocomplete="phone" class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="000000000" />
                </div>
              </div>
            </div>
          </div>

          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="col-span-full">
              <h2 class="text-sm font-semibold leading-7 text-gray-900">Autres informations</h2>
            </div>

            <div class="sm:col-span-4">
              <label for="cash-register" class="block text-sm font-medium leading-6 text-gray-900">Logiciel de caisse</label>
              <div class="mt-2">
                <input type="text"
                       formControlName="cashierProgram"
                       name="cash-register" id="cash-register" autocomplete="cash-register"
                       class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                       placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
              </div>
            </div>

            <div class="sm:col-span-4">
              <label for="iban" class="block text-sm font-medium leading-6 text-gray-900">IBAN</label>
              <div class="mt-2">
                <input type="text"
                       formControlName="iban"
                       name="iban" id="iban" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="mt-6 flex items-center justify-end gap-x-6">
        <button (click)="nav.goToLogin()" type="button" class="text-sm font-semibold leading-6 text-gray-900">Annuler</button>
        <button type="submit"
                (click)="sendRequest"
                class="rounded-md bg-stone-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">Envoyer la demande</button>
      </div>
    </form>
  </div>


