import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {PageLoginComponent} from "./page/page-login/page-login.component";
import {Page404Component} from "./page/page404/page404.component";
import {DashboardComponent} from "./page/dashboard/dashboard.component";
import { ChildRetailerComponent } from './page/dashboard-children/child-retailer/child-retailer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatNativeDateModule} from "@angular/material/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import { EditRetailerComponent } from './micro-component/edit-retailer/edit-retailer.component';
import { ChildSalesRevenueComponent } from './page/dashboard-children/child-sales-revenue/child-sales-revenue.component';
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatListModule} from "@angular/material/list";
import {NgForOf, NgIf} from "@angular/common";
import {BffApiService} from "./shared/bff-api.service";
import {HttpClientModule} from "@angular/common/http";
import {PageTransactionsComponent} from "./page/dashboard-children/page-transactions/page-transactions.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AddRetailerComponent} from "./page/retailers-action/add-retailer/add-retailer.component";
import {RetailersListComponent} from "./page/retailers-action/retailers-list/retailers-list.component";
import {RetailersRequestsComponent} from "./page/retailers-action/retailers-requests/retailers-requests.component";

@NgModule({
  declarations: [
    AppComponent,
    PageLoginComponent,
    Page404Component,
    DashboardComponent,
    ChildRetailerComponent,
    EditRetailerComponent,
    ChildSalesRevenueComponent,
    PageTransactionsComponent,
    AddRetailerComponent,
    RetailersListComponent,
    RetailersRequestsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatCheckboxModule,
    MatListModule,
    MatTableModule,
    NgForOf,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    NgForOf,
    ReactiveFormsModule
  ],
  providers: [
    BffApiService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
