import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {orders} from "../../../../../../fe-global-components/mockup_data/mockup_data";

@Component({
  selector: 'app-page-transactions',
  templateUrl: './page-transactions.component.html',
  styleUrl: './page-transactions.component.scss',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PageTransactionsComponent implements OnInit {
  isTableExpanded = false;

  dataProductsList = new MatTableDataSource();
  displayedColumns: string[] = ['checkbox', 'name', 'categories', 'brand', 'actions'];


  ngOnInit() {
    this.dataProductsList.data = orders;
  }

  // Toggel Rows
  toggleTableRows() {
    this.isTableExpanded = !this.isTableExpanded;

    this.dataProductsList.data.forEach((row: any) => {
      row.isExpanded = this.isTableExpanded;
    })
  }
}
